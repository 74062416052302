import React from "react"
import { Link } from "gatsby"
import { toPairsIn } from "ramda"
import startCase from "lodash/startCase"
import { HeaderProps } from ".."
import { LaptopDownOnly, bp } from "../../../utils/MediaQueries"
import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Button,
  Icon,
  Box,
  PseudoBox,
  useDisclosure,
} from "@chakra-ui/core"
import Body from "../../typography/Body"
import Search from "../../Search/Search"
import styled from "@emotion/styled"
import Account from "../../Account"
import IconButton from "../../Buttons/IconButton"
import {
  SIDEBAR_WIDTH_OPEN,
  SIDEBAR_WIDTH_CLOSED,
} from "../../../constants/measurements"
import { ScrollbarWidthContext } from "../../../context/scrollbarWidth"
import { rem } from "../../../utils"
import { Branding } from "../../../redux/types/BrandingTypes"

const DrawerHeader = styled(Flex)``
const DrawerBodyContent = styled(Box)``

const PopLayerButton: React.FC<{
  onClick: VoidFunction
  children: string
}> = ({ children, onClick }) => (
  <PseudoBox bg="noon" onClick={onClick}>
    <Flex alignItems="center" p="1.375rem">
      <Icon name="chevron" size="1.25rem" color="dusk" />
      <Body size="small" textTransform="capitalize" color="dusk" ml="0.75rem">
        {children}
      </Body>
    </Flex>
  </PseudoBox>
)

const PushLayerButton: React.FC<{
  children: string
  onClick: VoidFunction
}> = ({ children, onClick }) => (
  <PseudoBox onClick={onClick}>
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p="1.375rem 1.25rem"
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
    >
      <Body
        size="sm"
        lineHeight="1.25rem"
        color="dusk"
        textTransform="capitalize"
      >
        {children}
      </Body>
      <Icon
        name="chevron"
        size="1.25rem"
        color="dusk"
        transform="rotate(180deg)"
      />
    </Flex>
  </PseudoBox>
)

const MobilePageLink: React.FC<{
  to: string
  children: string
  onClick?: VoidFunction
}> = ({ to, onClick, children }) => (
  <Link to={to} onClick={onClick}>
    <Body
      size="sm"
      fontWeight="bold"
      textTransform="capitalize"
      color="night"
      p="1.375rem 1.25rem"
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
      width="100%"
    >
      {children}
    </Body>
  </Link>
)

const LightPageLink: React.FC<{
  children: string
  to: string
  onClick?: VoidFunction
}> = ({ to, onClick, children }) => (
  <Link to={to} onClick={onClick}>
    <Body
      size="sm"
      color="dusk"
      lineHeight="1.25rem"
      textTransform="capitalize"
      p="1.375rem 1.25rem"
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
    >
      {children}
    </Body>
  </Link>
)

const MobileLinkText: React.FC = ({ children }) => (
  <Body
    size="sm"
    fontWeight="bold"
    lineHeight="1.25rem"
    color="night"
    textTransform="capitalize"
  >
    {children}
  </Body>
)

export default function MobileHeader({
  data,
  path,
  isSideBarOpen,
  branding,
  hasBodyFitFullScreenLayout,
}: HeaderProps) {
  // Drawer State items
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [mobileSecondLevelNav, setMobileSecondLevelNav] =
    React.useState<string>("")
  const [mobileThirdLevelNav, setMobileThirdLevelNav] =
    React.useState<string>("")
  const scrollbarWidth = React.useContext(ScrollbarWidthContext)

  const resetMobileSecondLevelNav = () => setMobileSecondLevelNav("")
  const resetMobileThirdLevelNav = () => setMobileThirdLevelNav("")

  React.useEffect(() => {
    onClose()
    setMobileSecondLevelNav("")
    setMobileThirdLevelNav("")
  }, [path])

  const mobileSecondLevelNavData = data.find(
    (item) => item.text === mobileSecondLevelNav
  )
  const mobileThirdLevelNavData =
    mobileSecondLevelNavData?.dropDownLinks?.[mobileThirdLevelNav]

  const mobileThirdLevelNavDataAlt =
    mobileThirdLevelNav &&
    mobileSecondLevelNavData?.dropDownColumns?.[mobileThirdLevelNav]["links"]

  const sidebarWidth = isSideBarOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED
  const contentWidth = `calc(100vw - ${rem(sidebarWidth + scrollbarWidth)}rem)`

  return (
    <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        h="3.5rem"
        w={bp("100vw", hasBodyFitFullScreenLayout ? "100vw" : contentWidth)}
        px="0.5rem"
        pos="fixed"
        bg="backgroundOpaque"
        zIndex={50}
      >
        <IconButton
          aria-label="open navigation"
          borderRadius="0"
          icon="drawer"
          size="1.25rem"
          h="100%"
          w="3.25rem"
          onClick={onOpen}
        />
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerBody p="0">
                {/* Header */}
                <DrawerHeader
                  h="5rem"
                  alignItems="center"
                  borderBottom="1px solid"
                  borderColor="dividerLine"
                >
                  <Button
                    backgroundColor="white"
                    borderRadius="0px"
                    borderRight="1px solid"
                    borderColor="dividerLine"
                    flexGrow={1}
                    h="100%"
                    _hover={{
                      backgroundColor: "dividerLine",
                    }}
                    _active={{
                      backgroundColor: "noon",
                      color: "night",
                      outline: "none",
                    }}
                    onClick={() => {
                      onClose()
                      resetMobileSecondLevelNav()
                      resetMobileThirdLevelNav()
                    }}
                  >
                    <Flex align="flex-end" justify="center">
                      <Icon name="x" size="1.25rem" color="dusk" />
                      <Body
                        size="sm"
                        fontWeight="normal"
                        lineHeight="1.125rem"
                        color="dusk"
                        ml="0.75rem"
                      >
                        Close Menu
                      </Body>
                    </Flex>
                  </Button>
                  <Flex
                    align="center"
                    justify="center"
                    w="5rem"
                    h="100%"
                    borderRight="1px solid"
                    borderColor="dividerLine"
                  >
                    <Search
                      h="100%"
                      width="100%"
                      borderRadius="0px"
                      border="0px"
                      isSideBarOpen={isSideBarOpen}
                    />
                  </Flex>
                  <Flex
                    align="center"
                    justify="center"
                    w="5rem"
                    h="100%"
                    borderRight="1px solid"
                    borderColor="dividerLine"
                  >
                    <Account
                      h="100%"
                      width="100%"
                      borderRadius="0px"
                      border="0px"
                      isSideBarOpen={!!isSideBarOpen}
                    />
                  </Flex>
                </DrawerHeader>
                <DrawerBodyContent>
                  {/*TODO: this will need to specifically exclude the more and just extract the links from there */}
                  {/* Mobile Nav Layer 1 */}
                  {data.map(
                    ({ dropDownColumns, dropDownLinks, title, text, to }) =>
                      to && dropDownColumns?.length === 0 && !dropDownLinks ? (
                        <Link to={to} key={text}>
                          <Box
                            p="1.5rem 1.25rem"
                            borderBottom="1px solid"
                            borderBottomColor="dividerLine"
                          >
                            <MobileLinkText>{title || text}</MobileLinkText>
                          </Box>
                        </Link>
                      ) : (
                        <PseudoBox
                          key={text}
                          p="1.5rem 1.25rem"
                          borderBottom="1px solid"
                          borderBottomColor="dividerLine"
                          _active={{ bg: "dividerLine" }}
                          onClick={() => setMobileSecondLevelNav(text)}
                        >
                          <Flex justify="space-between" align="center">
                            <MobileLinkText>{title || text}</MobileLinkText>
                            <Icon
                              name="chevron"
                              size="1.25rem"
                              transform="rotate(180deg)"
                              color="night"
                              fontWeight="bold"
                            />
                          </Flex>
                        </PseudoBox>
                      )
                  )}
                  {/* Mobile Nav Layer 2 */}
                  {mobileSecondLevelNavData && (
                    <Box
                      h="100vh"
                      bg="white"
                      w="100%"
                      top="5rem"
                      pos="absolute"
                      left="0"
                      zIndex={10}
                    >
                      <PopLayerButton onClick={resetMobileSecondLevelNav}>
                        {mobileSecondLevelNav}
                      </PopLayerButton>
                      <Box
                        pos="absolute"
                        w="100%"
                        key={mobileSecondLevelNavData.text}
                        zIndex={1}
                      >
                        {mobileSecondLevelNavData.allSlug && (
                          <MobilePageLink
                            to={mobileSecondLevelNavData.allSlug}
                            onClick={resetMobileSecondLevelNav}
                          >
                            All
                          </MobilePageLink>
                        )}

                        {Array.isArray(mobileSecondLevelNavData.dropDownColumns)
                          ? mobileSecondLevelNavData.dropDownColumns.map(
                              ({ title, links, titleLink }, idx) => {
                                return links.length === 0 ? (
                                  <MobilePageLink
                                    key={titleLink}
                                    to={titleLink ? titleLink : title}
                                    onClick={resetMobileSecondLevelNav}
                                  >
                                    {title}
                                  </MobilePageLink>
                                ) : (
                                  <PushLayerButton
                                    key={titleLink}
                                    onClick={() => {
                                      setMobileThirdLevelNav(idx.toString())
                                    }}
                                  >
                                    {startCase(title)}
                                  </PushLayerButton>
                                )
                              }
                            )
                          : toPairsIn(
                              mobileSecondLevelNavData.dropDownLinks || []
                            ).map(([key, value]) =>
                              key === "gender"
                                ? value.map(({ name, slug }) => (
                                    <MobilePageLink
                                      key={slug}
                                      to={slug}
                                      onClick={resetMobileSecondLevelNav}
                                    >
                                      {name}
                                    </MobilePageLink>
                                  ))
                                : value.length > 0 && (
                                    <PushLayerButton
                                      key={key}
                                      onClick={() => {
                                        setMobileThirdLevelNav(key)
                                      }}
                                    >
                                      {startCase(key)}
                                    </PushLayerButton>
                                  )
                            )}
                      </Box>
                    </Box>
                  )}
                  {/* Mobile Nav Layer 3 */}
                  {Array.isArray(mobileSecondLevelNavData?.dropDownColumns)
                    ? mobileThirdLevelNavDataAlt && (
                        <Box
                          h="calc(100vh - 5rem)"
                          bg="white"
                          w="100%"
                          top="5rem"
                          pos="absolute"
                          left="0"
                          zIndex={11}
                          overflowY="auto"
                        >
                          <PopLayerButton onClick={resetMobileThirdLevelNav}>
                            {startCase(
                              mobileSecondLevelNavData &&
                                mobileSecondLevelNavData.dropDownColumns?.[
                                  mobileThirdLevelNav
                                ]?.["title"]
                            )}
                          </PopLayerButton>
                          {mobileThirdLevelNavDataAlt.map(({ name, slug }) => (
                            <LightPageLink
                              key={name}
                              to={slug}
                              onClick={() => {
                                resetMobileSecondLevelNav()
                                resetMobileThirdLevelNav()
                              }}
                            >
                              {name}
                            </LightPageLink>
                          ))}
                        </Box>
                      )
                    : mobileThirdLevelNavData && (
                        <Box
                          h="calc(100vh - 5rem)"
                          bg="white"
                          w="100%"
                          top="5rem"
                          pos="absolute"
                          left="0"
                          zIndex={11}
                          overflowY="auto"
                        >
                          <PopLayerButton onClick={resetMobileThirdLevelNav}>
                            {startCase(mobileThirdLevelNav)}
                          </PopLayerButton>
                          {mobileThirdLevelNavData.map(({ name, slug }) => (
                            <LightPageLink
                              key={name}
                              to={slug}
                              onClick={() => {
                                resetMobileSecondLevelNav()
                                resetMobileThirdLevelNav()
                              }}
                            >
                              {name}
                            </LightPageLink>
                          ))}
                        </Box>
                      )}
                </DrawerBodyContent>
              </DrawerBody>
            </DrawerContent>
          </LaptopDownOnly>
        </Drawer>
        <Link to="/">
          {branding === Branding.sixthreezero && (
            <Icon name="logo" size="9.375rem" maxHeight="4rem" />
          )}
          {branding === Branding.electrified && (
            <Icon name="electrifiedLogo" size="9.375rem" maxHeight="4rem" />
          )}
        </Link>
        <Box
          as={Link}
          h="100%"
          //@ts-ignore
          to="/cart"
        >
          <IconButton
            aria-label="open cart"
            borderRadius="0"
            icon="cart"
            size="1.25rem"
            h="100%"
            w="3.25rem"
          />
        </Box>
      </Flex>
    </LaptopDownOnly>
  )
}
