import React from "react"
import { HeaderProps } from ".."
import { Flex, Icon, ModalOverlay, PseudoBox } from "@chakra-ui/core"
import debounce from "lodash/debounce"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import CartButton from "../../Cart/CartButton"
import SmallCaps from "../../typography/SmallCaps"
import Account from "../../Account"
import Search from "../../Search/Search"
import TwoColumnNav from "../TwoColumnNav"
import NavSection from "../NavSection"

import MultiColumnNav, { MultiColumnNavProps } from "../MultiColumnNav"
import { bp, LaptopDownOnly } from "../../../utils/MediaQueries"
import { MotionFlex } from "../../../utils/FramerMotion"
import { LaptopUpOnly } from "../../../utils/MediaQueries"
import useLayoutMeasurements from "../../../hooks/useLayoutMeasurements"
import { Branding } from "../../../redux/types/BrandingTypes"

const MainLink: React.FC<{
  onClick?: VoidFunction
  onHover?: VoidFunction
  active?: boolean
}> = ({ children, onClick, onHover, active = false }) => {
  return (
    <PseudoBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100%"
      px="1.375rem"
      borderBottom="1px solid"
      color={active ? "primary" : "dusk"}
      borderBottomColor={active ? "primary" : "rgba(0,0,0,0)"}
      onClick={onClick}
      onMouseOver={onHover}
      _hover={{
        bg: "dividerLine",
        color: "dusk",
        cursor: "pointer",
      }}
      _active={{
        bg: "noon",
      }}
    >
      <SmallCaps pointerEvents="none" size="lg" fontWeight="medium">
        {children}
      </SmallCaps>
    </PseudoBox>
  )
}

const MainPageLink: React.FC<{
  to: string
  onClick?: VoidFunction
  onHover?: VoidFunction
  active?: boolean
}> = ({ to, onClick, onHover, active, children }) => (
  <Link to={to} css={css({ height: "100%" })} onClick={onClick}>
    <MainLink onHover={onHover} active={active}>
      {children}
    </MainLink>
  </Link>
)

export default function DesktopHeader({
  data,
  isSideBarOpen,
  branding,
  hasBodyFitFullScreenLayout,
}: HeaderProps) {
  const bikesNavRef = React.useRef<Element>(null)
  const electricNavRef = React.useRef<Element>(null)
  const accessoriesNavRef = React.useRef<Element>(null)
  const bikeAdviceNavRef = React.useRef<Element>(null)
  const [expandedNavHeight, setExpandedNavHeight] = React.useState(0)
  const layout = useLayoutMeasurements()

  const [dropDownOpenName, setDropDownOpenName] = React.useState<string | null>(
    null
  )
  const dropDownNameRef = React.useRef<string | null>(null)

  const updateExpandedNavHeight = React.useCallback(
    debounce(
      () => {
        setExpandedNavHeight(getExpandedNavHeight())
      },
      200,
      { leading: true }
    ),
    []
  )

  React.useEffect(() => {
    window.addEventListener("resize", updateExpandedNavHeight)

    return () => {
      window.removeEventListener("resize", updateExpandedNavHeight)
    }
  }, [])

  React.useEffect(() => {
    dropDownNameRef.current = dropDownOpenName
    updateExpandedNavHeight()
  }, [dropDownOpenName])

  const getExpandedNavHeight = () => {
    switch (dropDownNameRef.current) {
      case "bikes":
        return bikesNavRef.current?.getBoundingClientRect().height || 300
      case "electric bikes":
        return electricNavRef.current?.getBoundingClientRect().height || 300
      case "accessories":
        return accessoriesNavRef.current?.getBoundingClientRect().height || 300
      case "bike advice":
        return bikeAdviceNavRef.current?.getBoundingClientRect().height || 300
      default:
        return 0
    }
  }

  // Skip adding links for mentioned navigation items.
  const omitPageLinksFor = ["bikes", "bike advice"]

  return (
    <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
      <Flex
        h="4rem"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderBottomColor="dividerLine"
        zIndex={1001}
        position="fixed"
        bg="white"
        // w={"100%"}
        w={bp(
          "unset",
          hasBodyFitFullScreenLayout ? "100vw" : layout.contentWidthDesktop
        )}
      >
        <Flex
          as={Link}
          height="100%"
          align="center"
          justify="center"
          // @ts-ignore
          to="/"
          onClick={() => setDropDownOpenName(null)}
        >
          <PseudoBox
            _hover={{
              bg: "dividerLine",
              cursor: "pointer",
            }}
            h="100%"
            px="1.75rem"
            onMouseEnter={() => setDropDownOpenName(null)}
          >
            {branding === Branding.sixthreezero && (
              <>
                <Icon
                  display={["none", null, null, null, null, "block"]}
                  name="logo"
                  w="8.75rem"
                  h="100%"
                  onMouseEnter={() => setDropDownOpenName(null)}
                />
                <Icon
                  display={["block", null, null, null, null, "none"]}
                  name="logoText"
                  w="5.75rem"
                  h="100%"
                  onMouseEnter={() => setDropDownOpenName(null)}
                />
              </>
            )}
            {branding === Branding.electrified && (
              <Icon
                name="electrifiedLogo"
                w="11rem"
                h="100%"
                onMouseEnter={() => setDropDownOpenName(null)}
              />
            )}
          </PseudoBox>
        </Flex>
        <Flex
          as="nav"
          pos="absolute"
          left="0"
          right="0"
          zIndex={-1}
          margin="auto"
          h="100%"
          align="center"
          justify="center"
        >
          {data.map(({ dropDownLinks, dropDownColumns, title, text, to }) => {
            return to && !omitPageLinksFor.includes(text) ? (
              <MainPageLink
                key={text}
                to={to}
                active={dropDownOpenName === text}
                onHover={() => {
                  if (dropDownColumns?.length !== 0 || dropDownLinks) {
                    setDropDownOpenName(text)
                  } else {
                    setDropDownOpenName(null)
                  }
                }}
                onClick={() => {
                  setDropDownOpenName(null)
                }}
              >
                {title ? title : text}
              </MainPageLink>
            ) : (
              <MainLink
                key={text}
                active={dropDownOpenName === text}
                onClick={() => {
                  setDropDownOpenName(dropDownOpenName === text ? null : text)
                }}
                onHover={() => {
                  // setDropDownOpenName(text)
                  setDropDownOpenName(null)
                }}
              >
                {title ? title : text}
              </MainLink>
            )
          })}
        </Flex>
        <Flex h="100%" onMouseEnter={() => setDropDownOpenName(null)}>
          <CartButton
            h="100%"
            w="3.25rem"
            borderRadius="0"
            onClick={() => setDropDownOpenName(null)}
            onMouseEnter={() => setDropDownOpenName(null)}
          />
          <LaptopUpOnly isSideBarOpen={isSideBarOpen} h="100%">
            <Search
              h="100%"
              w="3.25rem"
              borderRadius="0"
              onClick={() => setDropDownOpenName(null)}
              onMouseEnter={() => setDropDownOpenName(null)}
              isSideBarOpen={!!isSideBarOpen}
            />
            <Account
              h="100%"
              w="3.25rem"
              borderRadius="0"
              onClick={() => setDropDownOpenName(null)}
              onMouseEnter={() => setDropDownOpenName(null)}
              isSideBarOpen={!!isSideBarOpen}
            />
          </LaptopUpOnly>
        </Flex>
      </Flex>
      <MotionFlex
        className="DesktopHeaderDropdown"
        onMouseLeave={() => setDropDownOpenName(null)}
        position="fixed"
        top="3.5rem"
        left="0"
        zIndex={1003}
        justify="center"
        bg="white"
        w={layout.contentWidthDesktop}
        overflow="hidden"
        animate={{
          height: expandedNavHeight,
        }}
        transition={{ type: "spring", duration: 0.4 }}
      >
        <NavSection ref={bikesNavRef} show={dropDownOpenName === "bikes"}>
          <TwoColumnNav
            columnOneTitle="All"
            columnOneUrl={data[0].allSlug}
            columnOne={data[0]?.dropDownLinks?.gender || []}
            columnTwoTitle="Styles"
            columnTwo={data[0]?.dropDownLinks?.styles || []}
            onLinkClick={() => setDropDownOpenName(null)}
          />
        </NavSection>
        <NavSection
          ref={electricNavRef}
          show={dropDownOpenName === "electric bikes"}
        >
          <TwoColumnNav
            columnOneTitle="All"
            columnOneUrl={data[1]?.allSlug}
            columnOne={data[1]?.dropDownLinks?.gender || []}
            columnTwoTitle="Styles"
            columnTwo={data[1]?.dropDownLinks?.styles || []}
            onLinkClick={() => setDropDownOpenName(null)}
          />
        </NavSection>
        <NavSection
          ref={accessoriesNavRef}
          show={dropDownOpenName === "accessories"}
        >
          <MultiColumnNav
            w="100%"
            justify="center"
            columns={
              data[2].dropDownColumns
                ? (data[2].dropDownColumns.filter(
                    (x) => x !== null
                  ) as MultiColumnNavProps["columns"])
                : []
            }
            onLinkClick={() => setDropDownOpenName(null)}
            onShowChange={() => {
              setTimeout(() => updateExpandedNavHeight(), 0)
            }}
          />
        </NavSection>
        <NavSection
          ref={bikeAdviceNavRef}
          show={dropDownOpenName === "bike advice"}
        >
          <MultiColumnNav
            w="100%"
            justify="center"
            columns={
              data[3].dropDownColumns
                ? (data[3].dropDownColumns.filter(
                    (x) => x !== null
                  ) as MultiColumnNavProps["columns"])
                : []
            }
            onLinkClick={() => setDropDownOpenName(null)}
            onShowChange={() => {
              setTimeout(() => updateExpandedNavHeight(), 0)
            }}
          />
        </NavSection>
      </MotionFlex>
      {dropDownOpenName && (
        <ModalOverlay
          onMouseEnter={() => setDropDownOpenName(null)}
          className="test"
          zIndex={1000}
          onClick={() => setDropDownOpenName(null)}
        />
      )}
    </LaptopUpOnly>
  )
}
