import { AnswersType } from "../models/quiz"
import * as QuizTypes from "../types/QuizTypes"
import { convertHeightAnswerToInches } from "./bikes"

export function shouldGoToQuestion(
  question: QuizTypes.Question,
  answers: AnswersType
) {
  const answerValues = Object.values(answers)
  const includeByAnswerIds = Object.entries(question?.includeByAnswer || {})

  // Check if question is from a branch
  if (question.includeByAnswer === null || includeByAnswerIds.length === 0) {
    return true
  }

  // Check if we should continue to this branch
  return !!includeByAnswerIds.find(([answerId, includeByAnswer]) => {
    if (
      includeByAnswer.answerType === "ContentfulBodyFitQuizHeightRange" ||
      includeByAnswer.answerType === "ContentfulBodyFitQuizWeightRange"
    ) {
      const height = convertHeightAnswerToInches(answers.height)
      const min = includeByAnswer.range.min || 0
      const max = includeByAnswer.range.max || Infinity
      if (height && height >= min && height <= max) {
        return true
      }
      return false
    } else if (includeByAnswer.answerType === "Custom") {
      return answers[includeByAnswer.questionId] === includeByAnswer.answerId
    } else if (answerValues.includes(answerId)) {
      return true
    }
    return false
  })
}

export function getNextQuestion({
  currentQuestionIndex,
  questions,
  answers,
  ignoreUnanswered = true,
}: {
  currentQuestionIndex: number
  questions: QuizTypes.Question[]
  answers: AnswersType
  ignoreUnanswered?: boolean
}) {
  const isNotOutOfQuestionBounds = currentQuestionIndex < questions.length
  const hasCurrentQuestionBeenAnswered =
    answers[questions[currentQuestionIndex].questionId] !== undefined
  if (
    isNotOutOfQuestionBounds &&
    (hasCurrentQuestionBeenAnswered || !ignoreUnanswered)
  ) {
    const nextIndex = questions.findIndex((question, index) => {
      if (index <= currentQuestionIndex) return false
      return shouldGoToQuestion(question, answers)
    })
    if (nextIndex === -1) {
      return null
    } else {
      return {
        index: nextIndex,
        question: questions[nextIndex],
      }
    }
  }
  return null
}

export function getPrevQuestion({
  currentQuestionIndex,
  questions,
  answers,
}: {
  currentQuestionIndex: number
  questions: QuizTypes.Question[]
  answers: AnswersType
}) {
  if (currentQuestionIndex > 0) {
    const prevIndexDiff = questions
      .slice(0, currentQuestionIndex)
      .reverse()
      .findIndex((question) => {
        return shouldGoToQuestion(question, answers)
      })
    const index = currentQuestionIndex - (prevIndexDiff + 1)
    return {
      index,
      question: questions[index],
    }
  }
  return null
}

export function filterQuestions<T extends QuizTypes.Question>({
  questions,
  answers,
}: {
  questions: T[]
  answers?: AnswersType
}) {
  return questions.reduce<T[]>(
    (acc, next, index) => {
      if (answers) {
        const nextQuestion = getNextQuestion({
          currentQuestionIndex: index,
          questions,
          answers,
          ignoreUnanswered: false,
        })

        if (nextQuestion && !acc.includes(questions[nextQuestion.index])) {
          acc.push(questions[nextQuestion.index])
        }
      }

      return acc
    },
    [questions[0]]
  )
}
